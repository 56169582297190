@import '~flag-icon-css/less/flag-icons';

@flag-icons-path: '~flag-icon-css/flags';

// `en` alias of `gb`
.flag-icon-en {
  background-image: ~'url(@{flag-icons-path}@{flag-icons-rect-path}/gb.svg)';

  &.flag-icon-squared {
    background-image: ~'url(@{flag-icons-path}@{flag-icons-square-path}/gb.svg)';
  }
}
